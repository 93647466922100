// Основные шрифты
$font-primary: "Whitney", sans-serif;
$font-secondary: "PF Din Text Comp Pro", sans-serif;

// Основные цветов
$color-white: #fff;
$color-super-black: #19191A;
$color-black: #2c2c2c;
$color-dark-grey-blue: #63657e;
$color-grey-blue: #8e90ae;
$color-light-gray-50: #f3f5f9;
$color-light-gray-70: #eaebee;
$color-light-gray-100: #c5c7cd;
$color-main-orange: #ff6a13;
$color-secondary-orange: #f09336;
$color-main-gradient: linear-gradient(to bottom, #f4bc41 0%, #ec622b 100%);
$color-red-error: #eb5353;
$color-red-error-stroke-white: #fb8f8f;
$color-black-dark-gray: #404147;
$color-black-gray: #84858a;
$color-red-dark: #c61c1c;
$color-red-normal: #d7313b;
$color-red-light: #ff5959;
$color-red-very-light: #ffe1e1;
$color-red-very-very-light: #ffefef;
$color-orange-darkness: #9D2F00;
$color-orange-dark: #c6451c;
$color-orange-normal: #ff6e1d;
$color-orange-light: #ffb46f;
$color-orange-very-light: #fff0e1;
$color-yellow-dark: #c6961c;
$color-yellow-normal: #ffd12f;
$color-yellow-light: #ffee59;
$color-yellow-very-light: #fff8e1;
$color-light-green-dark: #9ac61c;
$color-light-green-normal: #adff00;
$color-light-green-light: #d9ff87;
$color-light-green-very-light: #f0ffd8;
$color-green-dark: #189349;
$color-green-normal: #1dc25f;
$color-green-light: #81ffb3;
$color-green-very-light: #d4ffe5;
$color-turquoise-dark: #0ec9d4;
$color-turquoise-normal: #2ff3ff;
$color-turquoise-light: #94fff9;
$color-turquoise-very-light: #e1ffff;
$color-blue-dark: #0065c1;
$color-blue-normal: #2f9bff;
$color-blue-light: #afe2ff;
$color-blue-very-light: #e1f6ff;
$color-dark-blue-dark: #1337b6;
$color-dark-blue-normal: #2f44ff;
$color-dark-blue-light: #94b8ff;
$color-dark-blue-very-light: #e1edff;
$color-violet-dark: #5113b6;
$color-violet-normal: #652fff;
$color-violet-light: #cab8ff;
$color-violet-very-light: #e9e1ff;
$color-purple-dark: #b213b6;
$color-purple-normal: #fb2fff;
$color-purple-light: #f8a5ff;
$color-purple-very-light: #fee1ff;
$color-pink-dark: #b61361;
$color-pink-normal: #ff2f93;
$color-pink-light: #ffa0ce;
$color-pink-very-light: #ffe1f3;

// Текстовые стили
@mixin text-style-pf-din-italic-h-80-m() {
  font-size: 80px;
  font-family: $font-secondary;
  font-weight: 500;
  font-style: normal;
  line-height: 110%;
  text-decoration: none;
  text-transform: uppercase;
}
@mixin text-style-pf-din-italic-h-72-m() {
  font-size: 72px;
  font-family: $font-secondary;
  font-weight: 500;
  font-style: normal;
  line-height: 110%;
  text-decoration: none;
  text-transform: uppercase;
}
@mixin text-style-pf-din-italic-h-64-m() {
  font-size: 64px;
  font-family: $font-secondary;
  font-weight: 500;
  font-style: normal;
  line-height: 110%;
  text-decoration: none;
  text-transform: uppercase;
}
@mixin text-style-pf-din-italic-h-56-m() {
  font-size: 56px;
  font-family: $font-secondary;
  font-weight: 500;
  font-style: normal;
  line-height: 110%;
  text-decoration: none;
  text-transform: uppercase;
}
@mixin text-style-pf-din-italic-h-48-m() {
  font-size: 48px;
  font-family: $font-secondary;
  font-weight: 500;
  font-style: normal;
  line-height: 110%;
  text-decoration: none;
  text-transform: uppercase;
}
@mixin text-style-pf-din-italic-h-40-m() {
  font-size: 40px;
  font-family: $font-secondary;
  font-weight: 500;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
  text-transform: uppercase;
}
@mixin text-style-pf-din-italic-h-32-m() {
  font-size: 32px;
  font-family: $font-secondary;
  font-weight: 500;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
  text-transform: uppercase;
}
@mixin text-style-pf-din-italic-h-24-m() {
  font-size: 24px;
  font-family: $font-secondary;
  font-weight: 500;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
  text-transform: uppercase;
}
@mixin text-style-pf-din-italic-h-20-m() {
  font-size: 20px;
  font-family: $font-secondary;
  font-weight: 500;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
  text-transform: uppercase;
}
@mixin text-style-pf-din-italic-h-18-m() {
  font-size: 18px;
  font-family: $font-secondary;
  font-weight: 500;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
  text-transform: uppercase;
}
@mixin text-style-pf-din-italic-h-16-m() {
  font-size: 16px;
  font-family: $font-secondary;
  font-weight: 500;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
  text-transform: uppercase;
}
@mixin text-style-whitney-h-24-m() {
  font-size: 24px;
  font-family: $font-primary;
  font-weight: 500;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
  text-transform: none;
}
@mixin text-style-whitney-h-20-sb() {
  font-size: 20px;
  font-family: $font-primary;
  font-weight: 600;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
  text-transform: none;
}
@mixin text-style-whitney-t-20-m() {
  font-size: 20px;
  font-family: $font-primary;
  font-weight: 500;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
  text-transform: none;
}
@mixin text-style-whitney-t-20-l-r() {
  font-size: 20px;
  font-family: $font-primary;
  font-weight: 400;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
  text-transform: none;
}
@mixin text-style-whitney-t-20-r() {
  font-size: 20px;
  font-family: $font-primary;
  font-weight: 400;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
  text-transform: none;
}
@mixin text-style-whitney-t-18-m() {
  font-size: 18px;
  font-family: $font-primary;
  font-weight: 500;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
  text-transform: none;
}
@mixin text-style-whitney-t-18-l-r() {
  font-size: 18px;
  font-family: $font-primary;
  font-weight: 400;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
  text-transform: none;
}
@mixin text-style-whitney-t-18-r() {
  font-size: 18px;
  font-family: $font-primary;
  font-weight: 400;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
  text-transform: none;
}
@mixin text-style-whitney-h-16-sb() {
  font-size: 16px;
  font-family: $font-primary;
  font-weight: 600;
  font-style: normal;
  line-height: 120%;
  text-decoration: none;
  text-transform: none;
}
@mixin text-style-whitney-t-16-m() {
  font-size: 16px;
  font-family: $font-primary;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  text-decoration: none;
  text-transform: none;
}
@mixin text-style-whitney-t-16-l-r() {
  font-size: 16px;
  font-family: $font-primary;
  font-weight: 400;
  font-style: normal;
  line-height: 150%;
  text-decoration: none;
  text-transform: none;
}
@mixin text-style-whitney-t-16-r() {
  font-size: 16px;
  font-family: $font-primary;
  font-weight: 400;
  font-style: normal;
  line-height: 20px;
  text-decoration: none;
  text-transform: none;
}
@mixin text-style-whitney-t-14-m() {
  font-size: 14px;
  font-family: $font-primary;
  font-weight: 500;
  font-style: normal;
  line-height: 130%;
  text-decoration: none;
  text-transform: none;
}
@mixin text-style-whitney-t-14-r() {
  font-size: 14px;
  font-family: $font-primary;
  font-weight: 400;
  font-style: normal;
  line-height: 130%;
  text-decoration: none;
  text-transform: none;
}
@mixin text-style-whitney-t-14-r-l() {
  font-size: 14px;
  font-family: $font-primary;
  font-weight: 400;
  font-style: normal;
  line-height: 160%;
  text-decoration: none;
  text-transform: none;
}
@mixin text-style-whitney-t-12-r() {
  font-size: 12px;
  font-family: $font-primary;
  font-weight: 400;
  font-style: normal;
  line-height: 130%;
  text-decoration: none;
  text-transform: none;
}

// Настройки проекта
$xy-grid: false;
$global-flexbox: true;
$global-font-size: 16px;
$global-width: 1200px;
$body-background: #19191A;
$body-font-family: $font-primary;
$body-font-color: $color-black;
$transition-parameter: 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
$breakpoint-classes: (small medium large);
$breakpoints: (
    small: 0,
    medium: 700px,
    large: 1200px,
);
$grid-column-gutter: (
    small: 40px,
    medium: 40px,
    large: 30px,
);
